import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

import * as styles from "../index.module.scss"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"

SwiperCore.use([Autoplay])

interface Props {
  setCurrentIndex: (number) => void
  setSwiper: any
  items: any
}

const Slides: React.FC<Props> = React.memo(
  ({ setCurrentIndex, setSwiper, items }) => {
    return (
      <Swiper
        className={styles.swiperContainer}
        onSwiper={setSwiper}
        slidesPerView="auto"
        loop={true}
        speed={900}
        noSwiping={true}
        noSwipingClass="no-swipe"
        onSlideChange={swiper => setCurrentIndex(swiper.realIndex + 1)}
        autoplay={{ delay: 6000 }}
      >
        {items.map(item => {
          return (
            <SwiperSlide
              key={item.image.localFile.id}
              className={classNames("no-swipe", styles.slide)}
            >
              <div className={styles.img}>
                <GatsbyImage
                  alt={item.image.alt}
                  image={item.image.localFile.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }
)

export default Slides
