import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "~/components/layout"
import HeroSection from "~/components/sections/home/hero"
import FutureSection from "~/components/sections/home/future"
import AboutSection from "~/components/sections/home/about"
import CompaniesSection from "~/components/sections/home/companies"
import NewsSection from "~/components/sections/home/news"
import SEO from "~/components/seo"

const IndexPage: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Helping Progressive Dairies" />
    <HeroSection />
    <AboutSection />
    <FutureSection />
    <CompaniesSection />
  </Layout>
)

export default IndexPage
